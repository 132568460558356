import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

export const LinkButton = ({
  children,
  type,
  green,
  smallRadius,
  whiteBorder,
  invertedButton,
  link,
}) => (
  <LinkButtonStyles
    type={type}
    green={green}
    whiteBorder={whiteBorder}
    smallRadius={smallRadius}
    invertedButton={invertedButton}
    onClick={(event) => {
      event.preventDefault();
      navigate(link);
    }}
  >
    {children}
  </LinkButtonStyles>
);

const LinkButtonStyles = styled.a`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.6rem;
  text-decoration: none;
  color: ${(props) => (props.invertedButton ? 'var(--blue)' : 'var(--white)')};
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 2rem;
  padding: 1.2rem 2rem;
  background: ${(props) =>
    props.invertedButton ? 'var(--white)' : 'var(--blue)'};
  border: ${(props) =>
    props.whiteBorder ? '2px solid var(--white)' : '2px solid var(--blue)'};
  border-radius: ${(props) => (props.smallRadius ? '1rem' : '5rem')};

  &:hover {
    background: ${(props) =>
      props.invertedButton ? 'var(--blue)' : 'var(--white)'};
    color: ${(props) =>
      props.invertedButton ? 'var(--white)' : 'var(--blue)'};
  }
`;
