import React from 'react';
import styled from 'styled-components';
import { CompanyInfo } from '../CompanyInfo';

export const CompanyButton = ({
  companyImg,
  companyName,
  category,
  handleClick,
}) => (
  <ButtonStyles onClick={handleClick}>
    <CustomCompanyInfo
      companyImg={companyImg}
      companyName={companyName}
      category={category}
      noBorderBottom
      noPadding
    />
  </ButtonStyles>
);

const CustomCompanyInfo = styled(CompanyInfo)`
  margin: 0;
  cursor: pointer;
`;

const ButtonStyles = styled.button`
  background: var(--white);
  box-shadow: 2px 2px 8px 0px hsl(0, 0%, 0%, 15%);
  border-radius: 0.6rem;
  border: none;
  margin-bottom: 1.3rem;
  padding: 2rem;
  flex: 1;
  cursor: pointer;

  @media (min-width: 768px) {
    max-width: 400px;
  }
`;
