import styled from 'styled-components';

export const Image = styled.img`
  max-height: calc(var(--maxHeightCardImage) / 1.7);
  max-width: calc(var(--maxWidthCardImage) / 1.7);

  object-fit: contain;
  align-self: baseline;
  aspect-ratio: 16 / 7;
  border: 1px solid black;

  @media (min-width: 768px) {
    max-height: var(--maxHeightCardImage);
    max-width: var(--maxWidthCardImage);
  }
`;
