import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const Loader = ({ size, className, variants }) => (
  <LoaderSVG
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <path d="M59.397 30.378C59.446 14.117 46.38.868 30.242.818 14.106.768.96 13.934.912 30.195c-.05 16.262 13.017 29.51 29.154 29.56 16.137.051 29.283-13.115 29.331-29.377Z" />
    <path
      d="M41.277 42.194 30.024 57.607l-11.16-15.483 22.413.07Z"
      fill="#0071EB"
      stroke="#fff"
      strokeWidth="2"
    />
    <path
      d="M30.197 14.657c8.54.026 15.478 7.043 15.452 15.678-.026 8.635-7.005 15.609-15.545 15.582-8.541-.027-15.478-7.044-15.452-15.679.025-8.635 7.004-15.608 15.545-15.581Z"
      fill="#FF6696"
      stroke="#fff"
      strokeWidth="3"
    />
  </LoaderSVG>
);

export const LoaderSVG = styled(motion.svg)`
  animation-name: rotate;
  animation: rotate 2s infinite linear;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
