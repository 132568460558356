import React from 'react';
import styled from 'styled-components';
import { GreenDotGreenCirle } from '../assets/icons/GreenDotGreenCirle';
import { RedDotRedCirle } from '../assets/icons/RedDotRedCirle';
import { RedDotRedCirleSmall } from '../assets/icons/RedDotRedCirleSmall';
import { CTACard, CTACardStyles } from './CTACard';

export const NewAdvertisementCard = ({ linktext }) => (
  <NewAdvertisementCardContainer>
    <NewAdvertisementCardStyles>
      <GreenDotGreenCirleSVG1 size={25} />
      <RedDotRedCirleSVG1 size={25} />

      <CTAContainer>
        <CTACard
          center
          bigPaddingBottom
          title="Tilbudsannonse"
          text="Legg ut en annonse med tidsbegrensning."
          linktext="Lag tilbudsannonse"
          linkTo="/app/offer-ad-form"
        />
        <CTACard
          center
          bigPaddingBottom
          title="Profilannonse"
          text="Profiler deg mot nærmiljøets beboere. Velg mellom 6 og 12 måneders
            varighet."
          linktext="Lag profilannonse"
          linkTo="/app/profile-ad-form"
        />
      </CTAContainer>

      <GreenDotGreenCirleSVG2 size={25} />
      <RedDotRedCirleSVG2 size={70} />
    </NewAdvertisementCardStyles>
  </NewAdvertisementCardContainer>
);

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 60;
  position: relative;
  margin-bottom: 3rem;

  ${CTACardStyles} {
    &:first-child {
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 1080px) {
    flex-direction: row;
    align-items: initial;

    ${CTACardStyles} {
      &:first-child {
        margin-right: 0.8rem;
        margin-bottom: 0;
      }

      &:last-child {
        margin-left: 0.8rem;
      }
    }
  }
`;

const NewAdvertisementCardContainer = styled.div`
  background-color: var(--seaGreen);
  padding-bottom: 3rem;
  margin-bottom: 3rem;
`;

const NewAdvertisementCardStyles = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  overflow: hidden;
  position: relative;
  min-height: 220px;
  max-width: var(--gridWidth);
  margin: 0 auto;

  @media (min-width: 768px) {
    overflow: inherit;
  }
`;

const GreenDotGreenCirleSVG1 = styled(GreenDotGreenCirle)`
  position: absolute;
  left: 5%;
  top: -3rem;
  overflow: hidden;
  z-index: 0;
`;

const GreenDotGreenCirleSVG2 = styled(GreenDotGreenCirle)`
  position: absolute;
  right: 3rem;
  top: 5rem;
  overflow: hidden;
  z-index: 0;
`;

const RedDotRedCirleSVG1 = styled(RedDotRedCirleSmall)`
  position: absolute;
  left: 8rem;
  bottom: 15%;
  overflow: hidden;
  z-index: 0;
`;

const RedDotRedCirleSVG2 = styled(RedDotRedCirle)`
  position: absolute;
  right: 4rem;
  bottom: 15%;
  overflow: hidden;
  z-index: 0;
`;
