import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { addMonths, format, setDate } from 'date-fns';
import {
  advertisementValidation,
  profileAdvertisement,
} from '../../utils/validationSchema';

import { useCreateAdvertisementContext } from '../../context/CreateAdvertisementContext';
import { useUserContext } from '../../context/UserContext';

import { Label } from './Label';
import { TextArea } from './TextArea';
import { Button } from '../Buttons/Button';
import { RadioButton } from './RadioButton';
import { FormSection } from './FormSection';
import { CompanyInfo } from '../CompanyInfo';
import { Input as CustomInput } from './Input';
import { Paragraph } from '../Paragraph';
import { ErrorMessage } from './ErrorMessage';
import { Image } from './Image';
import ImageResize from './ImageResize';

export default function ProfileAdvertisementForm() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { choosenCompany } = useUserContext();

  const {
    title,
    description,
    img,
    previewCroppedImage,
    startDate,
    endDate,
    advertisementType,
    externalUrl,
    setPreviewCroppedImage,
    setTitle,
    setDescription,
    setImg,
    setStartDate,
    setEndDate,
    setAdvertisementType,
    setExternalUrl,
  } = useCreateAdvertisementContext();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    function createByteArray(file) {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = function (e) {
        const buffer = e.target.result;

        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }

        setImg({
          ...img,
          path: URL.createObjectURL(event.target.files[0]),
          byteArray: window.btoa(binary),
        });
      };
      reader.onerror = function (error) {
        setError(true);
      };
    }
    createByteArray(file);
  };

  const formRef = useCallback(
    (node) => {
      if (node !== null) {
        setAdvertisementType(1);
        setTitle(node.values.title);
        setStartDate(node.values.startDate);
        setEndDate(node.values.endDate);
        setDescription(node.values.description);
        setExternalUrl(node.values.externalUrl);
      }
    },
    [
      setStartDate,
      setEndDate,
      setAdvertisementType,
      setTitle,
      setDescription,
      setExternalUrl,
    ]
  );

  return (
    <div>
      <Formik
        initialValues={{
          title,
          description,
          startDate: format(new Date(), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
          img,
          advertisementType,
          externalUrl,
        }}
        enableReinitialize
        innerRef={formRef}
        validationSchema={profileAdvertisement}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setTitle(values.title);
          setDescription(values.description);
          setStartDate(values.startDate);
          setEndDate(values.endDate);
          setExternalUrl(values.externalUrl);
          setAdvertisementType(1);
          navigate(`/app/form-preview-profile-ad`);
          setSubmitting(false);
        }}
      >
        {(props) => (
          <FormStyles>
            <OfferFor>Opprett profilannonse for:</OfferFor>

            <CompanyInfo
              companyName={choosenCompany.name}
              category={choosenCompany.category}
              companyImg={choosenCompany.thumbnailUrl}
            />

            {/** TITLE */}
            <FormSectionMBL>
              <CustomInput
                label="Tittel"
                name="title"
                type="text"
                boldPlaceholder
                placeholder="Tittel på profilannonse"
              />
            </FormSectionMBL>

            {/** DESCRIPTION */}
            <FormSection>
              <TextArea
                label="Beskrivelse"
                name="description"
                type="text"
                placeholder="Her skriver du litt om profilannonsen"
              />
            </FormSection>

            {/** URL */}
            <FormSectionMBL>
              <CustomInput
                label="Link"
                name="externalUrl"
                type="text"
                boldPlaceholder
                placeholder="Link til bedriftens hjemmeside"
              />
            </FormSectionMBL>

            {/** IMAGE */}
            <FormSection>
              <ImageResize
                img={img}
                previewCroppedImage={previewCroppedImage}
                setImg={setImg}
                setPreviewCroppedImage={setPreviewCroppedImage}
              />
            </FormSection>

            <HeaderH2>Profilannonsens varighet</HeaderH2>

            {/** END DATE */}
            <FormSection>
              <RadioButton
                text="12 måneder. Gratis annonsering i prøveperioden."
                type="radio"
                name="endDate"
                value={format(addMonths(new Date(), 12), 'yyyy-MM-dd')}
              />
            </FormSection>

            <FormSectionMBL>
              <RadioButton
                text="6 måneder. Gratis annonsering i prøveperioden."
                name="endDate"
                type="radio"
                value={format(addMonths(new Date(), 6), 'yyyy-MM-dd')}
              />
            </FormSectionMBL>

            <FormSectionMBL>
              <Button type="submit">Neste</Button>
            </FormSectionMBL>
          </FormStyles>
        )}
      </Formik>
    </div>
  );
}

const FormSectionMBL = styled(FormSection)`
  margin-bottom: 7rem;
`;

const FormStyles = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: var(--gridWidth);
  margin: 0 auto;
`;

const HeaderH2 = styled.h2`
  font-size: clamp(1.8rem, 3vw, 2.4rem);
  margin-bottom: 2rem;
  font-weight: 700;
`;

const OfferFor = styled.p`
  font-size: clamp(1.2rem, 3vw, 1.8rem);
`;
