import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const Heart = ({ size, className, variants }) => (
  <HeartSVG
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <g opacity="0.5">
      <circle cx="46" cy="46" r="46" fill="white" />
      <circle cx="46" cy="46" r="46" fill="white" />
      <circle cx="46" cy="46" r="46" fill="white" />
    </g>
    <circle cx="46" cy="46" r="34" fill="white" />
    <circle cx="46" cy="46" r="34" fill="white" />
    <circle cx="46" cy="46" r="34" fill="white" />
    <g>
      <path
        d="M46.0129 48.3509H62.4693C63.588 47.3042 64.4789 46.045 65.0881 44.6497C65.6974 43.2544 66.0122 41.7519 66.0136 40.2334C66.015 38.7149 65.7029 37.2119 65.0962 35.8155C64.4895 34.4191 63.6008 33.1584 62.4841 32.1097C61.3674 31.061 60.0458 30.2463 58.5994 29.7148C57.153 29.1833 55.612 28.9461 54.0697 29.0177C52.5274 29.0892 51.0159 29.4681 49.6268 30.1311C48.2377 30.7941 47 31.7276 45.9886 32.875V48.3509H46.0129Z"
        fill="#74E2BE"
      />
      <path
        d="M29.5079 48.3509L46.0122 63L62.5407 48.3509H29.5079Z"
        fill="#017AFF"
      />
      <path
        d="M29.5557 48.3508H46.0121V32.8748C44.491 31.1452 42.4682 29.9147 40.2153 29.3485C37.9624 28.7823 35.5874 28.9076 33.409 29.7074C31.2306 30.5073 29.3531 31.9435 28.0288 33.8232C26.7044 35.7029 25.9964 37.9361 26 40.223C26.0071 41.7432 26.3256 43.2464 26.9365 44.6429C27.5474 46.0394 28.4382 47.3004 29.5557 48.3508Z"
        fill="#FF6696"
      />
    </g>
    <defs>
      <clipPath>
        <rect
          width="40"
          height="34"
          fill="white"
          transform="translate(26 29)"
        />
      </clipPath>
    </defs>
  </HeartSVG>
);

export const HeartSVG = styled(motion.svg)`
  z-index: 120;
`;
