import React from 'react';
import styled from 'styled-components';

export const FormSection = ({ children, smallMargin, className }) => (
  <FormSectionStyles smallMargin={smallMargin} className={className}>
    {children}
  </FormSectionStyles>
);

const FormSectionStyles = styled.fieldset`
  margin-bottom: ${(props) => (props.smallMargin ? '2rem' : '3.4rem')};
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-bottom: ${(props) => (props.smallMargin ? '2rem' : '8rem')};
  }
`;
