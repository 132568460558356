/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { Label } from './Label';
import { ErrorMessage } from './ErrorMessage';

export const Input = ({
  label,
  boldPlaceholder,
  equalPadding,
  marginBottom,
  noBorder,
  text,
  locale,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <function > and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  return (
    <InputContainer noBorder={noBorder}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <InputStyles
        boldPlaceholder={boldPlaceholder}
        equalPadding={equalPadding}
        marginBottom={marginBottom}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </InputContainer>
  );
};

const InputStyles = styled.input`
  padding: 1rem 1rem 1rem 0;
  padding: ${(props) => (props.equalPadding ? '1rem' : '1rem 1rem 1rem 0')};
  border: none;
  font-family: ${(props) => (props.boldPlaceholder ? 'Poppins' : 'inherit')};
  font-weight: ${(props) => (props.boldPlaceholder ? 'bold' : 'inherit')};
  font-size: clamp(1.6rem, 3vw, 1.8rem);
  margin-bottom: ${(props) => (props.marginBottom ? '1.4rem' : '0')};
  font-weight: bold;

  -webkit-appearance: none;
  border-bottom: ${(props) =>
    props.noBorder ? 'none' : '1px solid var(--black)'};

  &::placeholder {
    color: var(--darkGrey);
    opacity: 1;
    font-weight: normal;
  }

  &:focus {
    outline: 2px solid var(--blue);
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
