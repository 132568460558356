import React from 'react';
import styled from 'styled-components';

export const CompanyInfo = ({
  white,
  big,
  center,
  noBorderBottom,
  noPadding,
  small,
  companyName,
  category,
  companyImg,
  className,
}) => (
  <CompanyInfoStyles
    white={white}
    big={big}
    center={center}
    noBorderBottom={noBorderBottom}
    noPadding={noPadding}
    className={className}
  >
    {companyImg ? (
      <ImgStyles src={companyImg} alt="Company logo" />
    ) : (
      <ImgPlaceholder>{companyName.charAt(0)}</ImgPlaceholder>
    )}
    <NameAndCategory>
      <Name small={small}>{companyName}</Name>
      <Category small={small}>
        {category !== undefined ? 'Kategori mangler' : category}
      </Category>
    </NameAndCategory>
  </CompanyInfoStyles>
);

export const CompanyInfoStyles = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.noBorderBottom ? 'none' : '1px solid var(--darkGrey)'};
  margin: ${(props) =>
    props.noBorderBottom ? '0 -2rem 0 -2rem' : '0 -2rem 3rem -2rem'};
  padding: ${(props) => (props.noPadding ? '0' : '0 2rem 1.5rem 2rem')};

  @media (min-width: 768px) {
    margin: 0 0 3rem 0;
  }
`;

const ImgPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-family: 'Poppins';
  font-weight: bold;
  font-size: ${(props) => (props.small ? '1.7rem' : '2.7rem')};
  height: ${(props) => (props.small ? '3rem' : '5rem')};
  width: ${(props) => (props.small ? '3rem' : '5rem')};
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid var(--grey);
  padding: 2px;
`;

const ImgStyles = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-family: 'Poppins';
  font-weight: bold;
  font-size: ${(props) => (props.small ? '1.7rem' : '2.7rem')};
  height: ${(props) => (props.small ? '3rem' : '5rem')};
  width: ${(props) => (props.small ? '3rem' : '5rem')};
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid var(--grey);
  background-color: var(--white);
  padding: 2px;
  text-indent: -9999px;
  object-fit: cover;
`;

const NameAndCategory = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

const Name = styled.h2`
  margin-bottom: 0;
  font-size: ${(props) => (props.small ? '1.4rem' : '1.6rem')};
  line-height: ${(props) => (props.small ? '1.6rem' : '2rem')};
  color: var(--black);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
`;

const Category = styled.span`
  font-size: ${(props) => (props.small ? '1.2rem' : '1.4rem')};
  line-height: ${(props) => (props.small ? '1.6rem' : '2rem')};
  text-align: left;
`;
