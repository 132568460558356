import styled from 'styled-components';

export const PreviewImage = styled.img`
  object-fit: contain;
  width: 100%;
  max-height: calc(var(--maxHeightCardImage) / 1.7);
  max-width: calc(var(--maxWidthCardImage) / 1.7);

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (min-width: 768px) {
    max-height: var(--maxHeightCardImage);
    max-width: var(--maxWidthCardImage);
  }
`;
