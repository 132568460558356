import React, { useCallback, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { compareAsc, format } from 'date-fns';
import { advertisementValidation } from '../../utils/validationSchema';

import { useUserContext } from '../../context/UserContext';
import { useCreateAdvertisementContext } from '../../context/CreateAdvertisementContext';

import 'react-image-crop/dist/ReactCrop.css';

import { Label } from './Label';
import { TextArea } from './TextArea';
import { Button } from '../Buttons/Button';
import { FormSection } from './FormSection';
import { CompanyInfo } from '../CompanyInfo';
import { Input as CustomInput } from './Input';
import ImageResize from './ImageResize';
import { Paragraph } from '../Paragraph';
import { Image } from './Image';

export default function AdvertisementForm() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { choosenCompany } = useUserContext();

  const {
    title,
    description,
    img,
    previewCroppedImage,
    startDate,
    endDate,
    discountCode,
    setPreviewCroppedImage,
    setDiscountCode,
    setTitle,
    setDescription,
    setStartDate,
    setEndDate,
    setImg,
  } = useCreateAdvertisementContext();

  const formRef = useCallback(
    (node) => {
      if (node !== null) {
        setStartDate(node.values.startDate);
        setEndDate(node.values.endDate);

        const endDateIsBeforeStartDate = compareAsc(
          new Date(node.values.startDate),
          new Date(node.values.endDate)
        );
        if (endDateIsBeforeStartDate === 1) {
          setStartDate(node.values.endDate);
        }

        const startDateIsAferEndDate = compareAsc(
          new Date(node.values.endDate),
          new Date(node.values.startDate)
        );
        if (startDateIsAferEndDate === -1) {
          setEndDate(node.values.startDate);
        }

        setTitle(node.values.title);
        setDescription(node.values.description);
      }
    },
    [setStartDate, setEndDate, setTitle, setDescription]
  );

  return (
    <div>
      <Formik
        initialValues={{
          title,
          description,
          startDate: startDate
            ? format(new Date(startDate), 'yyyy-MM-dd')
            : format(new Date(), 'yyyy-MM-dd'),
          endDate: endDate
            ? format(new Date(endDate), 'yyyy-MM-dd')
            : format(new Date(Date.now() + 3600 * 1000 * 24), 'yyyy-MM-dd'),
          discountCode,
        }}
        enableReinitialize
        innerRef={formRef}
        validationSchema={advertisementValidation}
        onSubmit={async (values, { setSubmitting }) => {
          setTitle(values.title);
          setDescription(values.description);
          setStartDate(values.startDate);
          setEndDate(values.endDate);
          setDiscountCode(values.discountCode);
          navigate(`/app/form-preview-offer-ad`);
          setSubmitting(false);
        }}
      >
        {(props) => (
          <FormStyles>
            <OfferFor>Opprett tilbud for:</OfferFor>

            <CompanyInfo
              companyName={choosenCompany.name}
              category={choosenCompany.choosenCompanyCategory}
              companyImg={choosenCompany.thumbnailUrl}
            />

            {/** TITLE */}
            <FormSectionMBL>
              <CustomInput
                label="Tittel"
                name="title"
                type="text"
                boldPlaceholder
                placeholder="Tittel på tilbudet"
              />
            </FormSectionMBL>

            {/** DESCRIPTION */}
            <FormSection>
              <TextArea
                label="Beskrivelse"
                name="description"
                type="text"
                placeholder="Her skriver du litt om tilbudet"
              />
            </FormSection>

            {/** IMAGE */}
            <FormSection>
              <ImageResize
                img={img}
                previewCroppedImage={previewCroppedImage}
                setImg={setImg}
                setPreviewCroppedImage={setPreviewCroppedImage}
              />
            </FormSection>

            <HeaderH2>Spesifiser tilbudet ditt</HeaderH2>

            {/** START DATE */}
            <FormSection>
              <CustomInput label="Startdato" name="startDate" type="date" />
            </FormSection>

            {/** END DATE */}
            <FormSection>
              <CustomInput label="Utløpsdato" name="endDate" type="date" />
            </FormSection>

            {/** DISCOUNT CODE */}
            <FormSectionMBL>
              <CustomInput
                label="Legg til rabattkode"
                name="discountCode"
                type="text"
                placeholder="Rabattkode"
              />
            </FormSectionMBL>

            <FormSectionMBL>
              <Button type="submit">Neste</Button>
            </FormSectionMBL>
          </FormStyles>
        )}
      </Formik>
    </div>
  );
}

const FormSectionMBL = styled(FormSection)`
  margin-bottom: 7rem;
`;

const FormStyles = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: var(--gridWidth);
  margin: 0 auto;
`;

const HeaderH2 = styled.h2`
  font-size: clamp(1.8rem, 3vw, 2.4rem);
  margin-bottom: 2rem;
  font-weight: 700;
`;

const OfferFor = styled.p`
  font-size: clamp(1.2rem, 3vw, 1.8rem);
`;
