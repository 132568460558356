import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { motion } from 'framer-motion';
import Cookies from 'js-cookie';

import { navigate } from 'gatsby';
import { isLoggedIn } from '../services/auth';

import { Intro } from '../components/Intro';

import VippsButton from '../assets/images/vipps-login-button.png';

import { useUserContext } from '../context/UserContext';
import { TextContainer } from '../components/TextContainer';
import { ImageMapUpsideDown } from '../components/BackgroundMapUpsideDown';
import { containerPage, itemPage } from '../utils/framer-motion-config';

export default function LoginPage() {
  const { setUser } = useUserContext();

  const [accessToken, setAccessToken] = useState('');
  const [firstName, setFirstName] = useState('');
  const [id, setId] = useState('');

  const setInLocalStorage = (user) =>
    window.localStorage.setItem('user', JSON.stringify(user));

  useEffect(() => {
    setId(Cookies.get('id'));
    setAccessToken(Cookies.get('accessToken'));
    setFirstName(Cookies.get('firstName'));
    setInLocalStorage({ accessToken, id, firstName });
    setUser({ accessToken, id, firstName });
  }, [accessToken, id, setUser, firstName]);

  if (isLoggedIn()) {
    navigate(`/app/my-companies`);
  }

  return (
    <LoginContainer
      variants={containerPage}
      initial="hidden"
      animate="show"
      layout
    >
      <ImageMapUpsideDown />

      <LoginContent variants={itemPage}>
        <TextContainer noOverlay>
          <Intro
            bigHeader
            title="Logg inn for å opprette annonser"
            text="En nærmiljø-plattform (app) der lokalt næringsliv kan profilere seg, og annonsere daglige tilbud til nærmiljøets innbyggere. Forutsatt at du har registrert din bedrift i Community appen bruker vi Vipps login for å verifisere at du er deg. Det koster ingenting, men gjør at du kan være trygg når du bruker appen."
          />
          <a href="/.netlify/functions/auth">
            <Image src={VippsButton} alt="Vipps login button" />
          </a>
        </TextContainer>
      </LoginContent>
    </LoginContainer>
  );
}

const Image = styled.img`
  max-width: 265px;

  @media (min-width: 768px) {
    max-width: 300px;
  }
`;

const LoginContainer = styled(motion.div)`
  background-color: var(--lightGrey);
  transition: 0.2s ease-in-out;
`;

const LoginContent = styled(motion.div)`
  padding: 3rem;

  @media (min-width: 1080px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto;
    padding: 8.6rem 0 0 0;
  }
`;
