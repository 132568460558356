import React from 'react';
import styled from 'styled-components';
import { NewAdvertisementCard } from '../NewAdvertisementCard';
import AdvertisementList from '../AdvertisementList';
import { TextBlock } from '../TextBlock';
import { useUserContext } from '../../context/UserContext';

export default function MyPage(props) {
  const { companies, choosenCompany } = useUserContext();

  return (
    <MyPageStyles>
      {companies.length < 1 ? (
        <TextBlockNoCompanies
          noMargin
          title="Ops!"
          text="For å bruke denne funksjonaliteten må du ha registrert en bedrift i appen som du administrerer."
        />
      ) : (
        <>
          <TextBlock
            center
            centerText
            title={`Hei ${choosenCompany.name}`}
            text="Her oppretter du annonser og tilbud som blir tilgjengelig i ditt nærmiljø."
          />
          <NewAdvertisementCard />
          <AdvertisementList {...props} companyId={choosenCompany.id} />
        </>
      )}
    </MyPageStyles>
  );
}

const TextBlockNoCompanies = styled(TextBlock)``;

const MyPageStyles = styled.div`
  min-height: calc(100vh - var(--navHeight) - var(--footerHeight));
  display: flex;
  flex-direction: column;

  ${TextBlockNoCompanies} {
    flex: 1;
  }
`;
