import styled from 'styled-components';

export const PreviewCard = styled.div`
  box-shadow: 2px 2px 8px 0px hsl(0, 0%, 0%, 15%);
  border-radius: 10px;
  max-width: calc(550px / 1.7);
  background-color: transparent;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: 550px;
  }
`;
