import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const RedDotRedCirle = ({ size, className, variants }) => (
  <RedDotRedCirleSVG
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 74 74"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <circle
      cx="37"
      cy="37"
      r="23"
      fill="#FF6696"
      stroke="#FFE1EB"
      strokeWidth="27"
    />
  </RedDotRedCirleSVG>
);

export const RedDotRedCirleSVG = styled(motion.svg)`
  z-index: 120;
`;
