import React from 'react';
import styled from 'styled-components';

export const Button = ({
  children,
  handleClick,
  type,
  color,
  smallRadius,
  whiteBorder,
  className,
  disabled,
}) => (
  <ButtonStyles
    type={type}
    color={color}
    whiteBorder={whiteBorder}
    smallRadius={smallRadius}
    onClick={handleClick}
    className={className}
    disabled={disabled}
  >
    {children}
  </ButtonStyles>
);

const ButtonStyles = styled.button`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
  transition: all 0.2s;
  margin-bottom: 2rem;
  padding: 1.2rem 3rem;
  background: ${(props) =>
    props.color ? `var(--${props.color})` : 'var(--blue)'};
  border: ${(props) =>
    props.color ? `1px solid var(--${props.color})` : '1px solid var(--blue)'};
  border-radius: ${(props) => (props.smallRadius ? '1rem' : '5rem')};
  min-width: 195px;
  width: fit-content;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  @media (min-width: 768px) {
  }
`;
