import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Header } from './Header';
import { Paragraph } from './Paragraph';

export const TextBlock = ({
  title,
  text,
  linktext,
  center,
  linkTo,
  big,
  bigPaddingBottom,
  noMargin,
  children,
  centerText,
  className,
}) => (
  <TextBlockContainer className={className}>
    <TextBlockStyles center={center} bigPaddingBottom={bigPaddingBottom}>
      {title && (
        <Header big bolder>
          {title}
        </Header>
      )}
      {text && (
        <TextBlockParagraph
          centerText={centerText}
          big={big}
          noMargin={noMargin}
        >
          {text}
        </TextBlockParagraph>
      )}
      {linktext && <LinkStyles to={linkTo}>{linktext}</LinkStyles>}
      {children}
    </TextBlockStyles>
  </TextBlockContainer>
);

const TextBlockParagraph = styled(Paragraph)`
  max-width: 720px;

  margin: 0 auto;
  @media (min-width: 768px) {
    margin: ${({ centerText }) => (centerText ? '0 auto' : '0')};
  }
`;

const TextBlockContainer = styled.div`
  background-color: var(--seaGreen);
  padding: 3rem;

  @media (min-width: 1080px) {
    width: 100%;
    padding: 8.6rem 0 2rem 0;
  }
`;

const TextBlockStyles = styled.div`
  padding-bottom: 3.2rem;
  max-width: var(--gridWidth);
  margin: 0 auto;

  @media (min-width: 768px) {
    text-align: ${(props) => (props.center ? 'center' : 'unset')};
  }
`;

const LinkStyles = styled(Link)`
  font-family: 'Poppins';
  font-weight: 700;
  margin-top: 2.4rem;
  font-size: 1.6rem;
  &:hover {
    text-decoration: none;
  }
`;
