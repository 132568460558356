import styled from 'styled-components';

import { HeartSVG } from '../assets/icons/Heart';
import { MusicSVG } from '../assets/icons/Music';
import { CalendarSVG } from '../assets/icons/Calendar';
import { HouseSVG } from '../assets/icons/House';
import { RedDotWhiteCirleSVG } from '../assets/icons/RedDotWhiteCirle';
import { GreenDotWhiteCirleSVG } from '../assets/icons/GreenDotWhiteCircle';

export const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(6, 1fr);
  max-width: var(--gridWidth);
  margin: 0 -2rem;
  overflow: hidden;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);

  ${MusicSVG} {
    grid-column: 1;
    grid-row: 6;
    margin-left: -0.5rem;
    margin-top: -1rem;
    overflow: hidden;
    display: none;
  }

  ${HouseSVG} {
    grid-column: 2 / 5;
    grid-row: 2 / 5;
    overflow: hidden;
  }

  ${CalendarSVG} {
    display: none;
  }

  ${GreenDotWhiteCirleSVG} {
    grid-column: 7;
    grid-row: 1;
    overflow: hidden;
  }

  ${GreenDotWhiteCirleSVG} {
    grid-column: 7;
    grid-row: 1;
    overflow: hidden;
  }

  ${RedDotWhiteCirleSVG} {
    grid-column: 6;
    grid-row: 2;
    overflow: hidden;
  }

  ${HeartSVG} {
    grid-column: 6;
    grid-row: 4;
    margin-left: 3rem;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    ${MusicSVG} {
      margin: 0;
      grid-column: 2;
      grid-row: 6;
      margin-left: -0.5rem;
      overflow: hidden;
      display: block;
    }

    ${CalendarSVG} {
      grid-column: 5;
      grid-row: 4;
      margin: 0;
      display: block;
      overflow: hidden;

      margin-top: 1rem;
    }

    ${GreenDotWhiteCirleSVG} {
      grid-column: 7;
      grid-row: 2;
    }

    ${GreenDotWhiteCirleSVG} {
      grid-column: 7;
      grid-row: 2;
    }
    ${RedDotWhiteCirleSVG} {
      grid-column: 1;
      grid-row: 3;
    }
    ${HeartSVG} {
      grid-column: 8;
      grid-row: 3;
      margin-top: -4rem;
    }
  }
`;
