import React, { useEffect, useState } from 'react';
import { format, formatISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useCreateAdvertisementContext } from '../../context/CreateAdvertisementContext';
import { useUserContext } from '../../context/UserContext';
import { CompanyInfo } from '../CompanyInfo';
import { Header } from '../Header';
import { Button } from '../Buttons/Button';
import { Paragraph } from '../Paragraph';
import { createAdvertisement } from '../../services/advertisements';
import { PreviewImage } from './PreviewImage';
import { PreviewCard } from './PreviewCard';

export const FormPreviewPageAdvertisement = () => {
  const {
    title,
    description,
    img,
    startDate,
    endDate,
    discountCode,
    advertisements,
    loadingAdvertisements,
    advertisementType,
    setStartDate,
    setEndDate,
    setTitle,
    setDescription,
    setImg,
    setDiscountCode,
    setAdvertisements,
    setLoadingAdvertisements,
    setAdvertisementType,
  } = useCreateAdvertisementContext();

  const [error, setError] = useState(false);

  const { user, choosenCompany } = useUserContext();

  useEffect(() => {
    setStartDate(formatISO(new Date(startDate)));
    setEndDate(formatISO(new Date(endDate)));
    setAdvertisementType(0);
  });

  const createOffer = async () => {
    setLoadingAdvertisements(true);

    const response = await createAdvertisement(
      user.accessToken,
      title,
      description,
      img,
      startDate,
      endDate,
      discountCode,
      choosenCompany.id,
      choosenCompany.communityId,
      advertisementType
    );

    if (response.status > 300 || response === undefined) {
      setError('Ops! Noe gikk galt, prøv å laste siden på nytt.');
      setLoadingAdvertisements(false);
    } else {
      const newAdvertisements = [...advertisements];
      newAdvertisements.push(response.data);
      setAdvertisements(newAdvertisements);
      setLoadingAdvertisements(false);
      setTitle('');
      setDescription('');
      setImg('');
      setDiscountCode('');
      navigate(`/app/my-page/${choosenCompany.id}`);
    }
  };

  return (
    <FormSucessPageStyles>
      <OfferFor>Opprett tilbud for:</OfferFor>

      <CompanyInfo
        companyName={choosenCompany.name}
        category={choosenCompany.category}
        companyImg={choosenCompany.thumbnailUrl}
      />

      <Header>Topp! Tilbudet er klart og kan publiseres i community.</Header>

      <Paragraph>
        Tilbudet vil være synlig fra{' '}
        {format(new Date(startDate), 'd MMM yyyy', { locale: nb })} til{' '}
        {format(new Date(endDate), 'd MMM yyyy', { locale: nb })}. Her ser du en
        forhåndsvisning av tilbudet:
      </Paragraph>

      <CardWrapper>
        <CompanyInfo
          companyName={choosenCompany.name}
          category={choosenCompany.category}
          companyImg={choosenCompany.thumbnailUrl}
          noBorderBottom
          small
        />

        <PreviewCard>
          {img?.path ? (
            <PreviewImage src={img?.path} id="preview-image" alt="" />
          ) : (
            <PlaceholderImage />
          )}
          <AdvertismentInfo>
            <CustomParagraph noMargin>
              Annonse |{' '}
              {choosenCompany.category !== undefined
                ? 'Kategori mangler'
                : choosenCompany.category}
            </CustomParagraph>
            <Title>{title}</Title>
            <Hr />
            <Discount>Rabattkode: {discountCode}</Discount>
          </AdvertismentInfo>
        </PreviewCard>
      </CardWrapper>

      <ButtonWrapper>
        <Button handleClick={createOffer}>
          {loadingAdvertisements ? 'Loading...' : 'Publiserer i community'}
        </Button>
      </ButtonWrapper>
      {error && (
        <ErrorMessage>Ops! Det skjedde noe galt, prøv igjen</ErrorMessage>
      )}
    </FormSucessPageStyles>
  );
};

const CustomParagraph = styled(Paragraph)`
  font-size: 1.3rem;
`;

const AdvertismentInfo = styled.div`
  padding: 1.6rem;
`;

const PlaceholderImage = styled.div`
  min-width: 300px;
  min-height: 200px;
  background-color: var(--grey);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const ErrorMessage = styled.p`
  color: var(--red);
  font-size: 2rem;
  font-weight: bold;
`;

const Title = styled.p`
  font-family: 'Poppins';
  font-weight: bold;
  margin-top: 0;
  font-size: 1.6rem;
`;

const Discount = styled.p`
  font-size: 1.6rem;
  margin-bottom: 0;
`;

const Hr = styled.hr`
  border: 1px solid var(--lightGrey);
  margin: -1rem -1.6rem 0 -1.6rem;
`;

const OfferFor = styled.p`
  font-size: clamp(1.2rem, 3vw, 1.8rem);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FormSucessPageStyles = styled.div`
  padding: 2rem;
  max-width: var(--gridWidth);
  margin: 0 auto;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6.2rem;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    margin-bottom: 6rem;
  }
`;
