import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const RedDotWhiteCirle = ({ size, className, variants }) => (
  <RedDotWhiteCirleSVG
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <g opacity="0.3">
      <circle cx="9" cy="9" r="9" fill="white" />
      <circle cx="9" cy="9" r="9" fill="white" />
      <circle cx="9" cy="9" r="9" fill="white" />
    </g>
    <ellipse
      cx="9.00001"
      cy="8.68965"
      rx="3.41379"
      ry="3.72414"
      fill="#FF6696"
    />
  </RedDotWhiteCirleSVG>
);

export const RedDotWhiteCirleSVG = styled(motion.svg)`
  z-index: 120;
`;
