import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '../components/PrivateRoute';
import ProfileAdvertisementForm from '../components/Form/ProfileAdvertisementForm';
import AdvertisementForm from '../components/Form/AdvertisementForm';
import EditAdvertisement from '../components/Form/EditAdvertisement';
import { FormPreviewPageAdvertisement } from '../components/ClientOnly/FormPreviewPageAdvertisement';
import { FormPreviewPageProfileAdvertisement } from '../components/ClientOnly/FormPreviewPageProfileAdvertisement';
import FormErrorPage from '../components/ClientOnly/FormErrorPage';
import Nav from '../components/Menu/Nav';
import LoginPage from './login';
import ComingSoonPage from './coming-soon';
import HomePage from './index';
import MyPage from '../components/ClientOnly/MyPage';
import MyCompanies from '../components/ClientOnly/MyCompanies';

const App = () => (
  <Router>
    <PrivateRoute path="/app/my-page/:id" component={MyPage} />
    <PrivateRoute path="/app/my-companies" component={MyCompanies} />

    {/** FORMS */}
    <PrivateRoute
      path="/app/profile-ad-form"
      component={ProfileAdvertisementForm}
    />
    <PrivateRoute path="/app/offer-ad-form" component={AdvertisementForm} />

    <PrivateRoute
      path="/app/edit-advertisement/:id"
      component={EditAdvertisement}
    />

    <PrivateRoute path="/app/form-error" component={FormErrorPage} />
    <PrivateRoute
      path="/app/form-preview-offer-ad"
      component={FormPreviewPageAdvertisement}
    />
    <PrivateRoute
      path="/app/form-preview-profile-ad"
      component={FormPreviewPageProfileAdvertisement}
    />
    <LoginPage path="/login" />
    <ComingSoonPage path="/coming-soon" />
    <HomePage path="/" />
    <Nav path="/" component={Nav} />
  </Router>
);

export default App;
