export const getCompanies = async (userId, accessToken) => {
  try {
    const url = `https://communityas-api.azurewebsites.net/api/User/${userId}/companies`;
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getCommunityIdForCompany = async (companyId, accessToken) => {
  try {
    const url = `https://communityas-api.azurewebsites.net/api/Company/${companyId}`;
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
};
