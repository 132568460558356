import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../Paragraph';

export default function FormErrorPage() {
  return (
    <FormErrorPageStyles>
      <h1>Ops!</h1>
      <Paragraph big>Det er ikke deg, det er meg... Noe gikk galt...</Paragraph>
    </FormErrorPageStyles>
  );
}

const FormErrorPageStyles = styled.div`
  padding: 2rem;
  background-color: var(--seaGreen);
  min-height: calc(100vh - var(--navHeight) - var(--footerHeight));
`;
