import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const Trash = ({ size, className, variants }) => (
  <TrashSVG
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 11 13"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <path
      d="M6.781 10.25h.563c.14 0 .281-.117.281-.281V4.906c0-.14-.14-.281-.281-.281H6.78a.285.285 0 0 0-.281.281V9.97a.27.27 0 0 0 .281.281Zm3.844-7.875H8.68l-.797-1.313A1.133 1.133 0 0 0 6.922.5H4.555c-.399 0-.774.234-.961.563l-.797 1.312H.875A.385.385 0 0 0 .5 2.75v.375c0 .21.164.375.375.375h.375v7.875A1.11 1.11 0 0 0 2.375 12.5h6.75c.61 0 1.125-.492 1.125-1.125V3.5h.375A.385.385 0 0 0 11 3.125V2.75a.403.403 0 0 0-.375-.375Zm-6.117-.68c.023-.023.07-.07.117-.07H6.852c.046 0 .093.047.117.07l.398.68H4.11l.399-.68Zm4.617 9.68h-6.75V3.5h6.75v7.875ZM4.156 10.25h.563c.14 0 .281-.117.281-.281V4.906c0-.14-.14-.281-.281-.281h-.563a.285.285 0 0 0-.281.281V9.97a.27.27 0 0 0 .281.281Z"
      fill="#0071EB"
    />
  </TrashSVG>
);

export const TrashSVG = styled(motion.svg)`
  z-index: 120;
`;
