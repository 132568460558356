import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { HeaderH2 } from './Header';

const Modal = ({ isShowing, hide, title, message }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <ModalOverlay />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <ModalStyles>
              <ModalHeader>
                <CloseButton
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                </CloseButton>
              </ModalHeader>
              <HeaderH2 noMargin>{title}</HeaderH2>
              <HeaderH2>{message}</HeaderH2>
            </ModalStyles>
          </ModalWrapper>
        </>,
        document.body
      )
    : null;

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
`;

const CloseButton = styled.button`
  font-size: 3rem;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 40%;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

const ModalStyles = styled.div`
  z-index: 100;
  background: var(--lightGrey);
  position: relative;
  margin: 0 auto;
  border-radius: 2rem;
  max-width: calc(100vw - 2rem);
  padding: 2rem;
  border: 4px solid var(--pink);

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;
