import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const Edit = ({ size, className, variants }) => (
  <EditSVG
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 14 13"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <path
      d="M9.922 8.586c-.047.047-.047.094-.047.14v2.649h-8.25v-8.25h5.648c.047 0 .094 0 .141-.047l.75-.75c.117-.117.024-.328-.14-.328H1.624C.992 2 .5 2.516.5 3.125v8.25A1.11 1.11 0 0 0 1.625 12.5h8.25c.61 0 1.125-.492 1.125-1.125V7.977c0-.165-.21-.258-.328-.141l-.75.75Zm3.656-4.711c.54-.54.54-1.406 0-1.945L12.57.922a1.372 1.372 0 0 0-1.945 0l-6.14 6.14-.235 2.133a.952.952 0 0 0 1.055 1.055l2.133-.234 6.14-6.141Zm-2.297.703-4.36 4.36-1.546.187.188-1.547 4.359-4.36 1.36 1.36Zm1.5-1.851c.117.093.117.234.024.351l-.727.727-1.36-1.383.704-.703a.22.22 0 0 1 .351 0l1.008 1.008Z"
      fill="#0071EB"
    />
  </EditSVG>
);

export const EditSVG = styled(motion.svg)`
  z-index: 120;
`;
