import * as Yup from 'yup';

export const advertisementValidation = Yup.object().shape({
  title: Yup.string().required('Påkrevd'),
  description: Yup.string().required('Påkrevd'),
  startDate: Yup.date().required('Påkrevd'),
  endDate: Yup.date().required('Påkrevd'),
});

export const profileAdvertisement = Yup.object().shape({
  title: Yup.string().required('Påkrevd'),
  description: Yup.string().required('Påkrevd'),
});
