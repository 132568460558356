import React from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import { Paragraph } from './Paragraph';

export const Intro = ({ title, text, bigHeader, bigText }) => (
  <IntroStyles>
    <Header bolder big={bigHeader}>
      {title}
    </Header>
    <Paragraph noMargin big={bigText}>
      {text}
    </Paragraph>
  </IntroStyles>
);

const IntroStyles = styled.div`
  margin-bottom: 4rem;
`;
