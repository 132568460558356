import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import { compareAsc } from 'date-fns';
import { nb } from 'date-fns/locale';
import { navigate } from 'gatsby';
import useModal from '../hooks/useModal';
import { Label } from './Form/Label';
import { HeaderH2, HeaderH2Styles } from './Header';
import Modal from './Modal';
import { Paragraph } from './Paragraph';
import { useUserContext } from '../context/UserContext';
import {
  deleteAdvertisement,
  getAdvertisements,
} from '../services/advertisements';
import { useCreateAdvertisementContext } from '../context/CreateAdvertisementContext';
import { Trash } from '../assets/icons/Trash';
import { Edit } from '../assets/icons/Edit';

export default function AdvertisementList() {
  const { isShowing, toggle } = useModal();
  const { user, choosenCompany } = useUserContext();
  const { setCurrentAdvertisement } = useCreateAdvertisementContext();

  const [advertisements, setAdvertisements] = useState([]);
  const [activeAdvertisements, setactiveAdvertisements] = useState([]);
  const [expiredAdvertisements, SetExpiredAdvertisements] = useState([]);

  const [error, setError] = useState(false);
  const [loadingactiveAdvertisements, setLoadingactiveAdvertisements] =
    useState(false);
  const [loadingExpiredAdvertisements, setLoadingExpiredAdvertisements] =
    useState(false);
  const [deletingAdvertisement, setDeletingAdvertisement] = useState(false);

  // Get all the Advertisements
  useEffect(() => {
    async function fetchAllAdvertisements() {
      setLoadingactiveAdvertisements(true);
      setLoadingExpiredAdvertisements(true);
      const response = await getAdvertisements(
        choosenCompany.id,
        user.accessToken
      );
      if (response.status > 300) {
        setError('Ops! Noe gikk galt, prøv å laste siden på nytt.');
      } else {
        setAdvertisements(response.results);
        setLoadingactiveAdvertisements(false);
        setLoadingExpiredAdvertisements(false);
      }
    }
    fetchAllAdvertisements();
  }, [user.id, user.accessToken, choosenCompany.id, setAdvertisements]);

  // Sort advertisements into active and expired lists
  useEffect(() => {
    if (advertisements.length >= 0) {
      const createNewAdvertisementLists = () => {
        const newExpiredOffers = advertisements.filter(
          (advertisement) =>
            compareAsc(new Date(advertisement.end), new Date()) === -1
        );
        SetExpiredAdvertisements(newExpiredOffers);
        setLoadingExpiredAdvertisements(false);

        const newactiveAdvertisements = advertisements.filter(
          (advertisement) =>
            compareAsc(new Date(advertisement.end), new Date()) === 1
        );
        setLoadingactiveAdvertisements(false);
        setactiveAdvertisements(newactiveAdvertisements);
      };

      createNewAdvertisementLists();
    } else {
      SetExpiredAdvertisements([]);
      setactiveAdvertisements([]);
    }
  }, [advertisements]);

  const deleteOffer = async (id) => {
    setDeletingAdvertisement(true);

    const response = await deleteAdvertisement(id, user.accessToken);

    if (response.status > 300 || response === undefined) {
      setError('Ops! Noe gikk galt, prøv å laste siden på nytt.');
      setDeletingAdvertisement(false);
    } else {
      let newAdvertisements = [...advertisements];
      newAdvertisements = advertisements.filter(
        (advertisement) => advertisement.id !== id
      );
      setAdvertisements(newAdvertisements);
      setDeletingAdvertisement(false);
    }
  };

  return (
    <OfferListContainer>
      <OfferListStyles>
        <Modal isShowing={isShowing} hide={toggle} />

        <Label>Aktive annonser</Label>
        <Hr />
        <ActiveOfferContainer>
          {loadingactiveAdvertisements ? (
            <p>{<Error>{error}</Error> || 'Loading...'}</p>
          ) : (
            <>
              {activeAdvertisements.length === 0 ? (
                <p>Det finnes ingen aktive annonser</p>
              ) : (
                activeAdvertisements.map((activeOffer) => (
                  <ActiveAdvertisementList key={activeOffer.id}>
                    {activeOffer.imageUrls ? (
                      <Image src={activeOffer.imageUrls} alt="" />
                    ) : (
                      <Image />
                    )}
                    <OfferText>
                      <CustomHeaderH2 noMargin>
                        {activeOffer.subject}
                      </CustomHeaderH2>
                      <CustomParagraph noMargin>
                        {activeOffer.category
                          ? activeOffer.category
                          : 'Kategori mangler'}
                      </CustomParagraph>
                      <CustomParagraph noMargin>
                        Løper til{' '}
                        {format(new Date(activeOffer.end), 'd MMM yyyy', {
                          locale: nb,
                        })}
                      </CustomParagraph>
                      <ButtonContainer>
                        <EditButton
                          onClick={() => {
                            setCurrentAdvertisement(activeOffer);
                            navigate(
                              `/app/edit-advertisement/${activeOffer.id}`
                            );
                          }}
                        >
                          <Edit size={14} /> <span>Rediger</span>
                        </EditButton>
                        <DeleteButton
                          onClick={() => deleteOffer(activeOffer.id)}
                        >
                          <Trash size={14} />
                          Slett
                        </DeleteButton>
                      </ButtonContainer>
                    </OfferText>
                  </ActiveAdvertisementList>
                ))
              )}
            </>
          )}
        </ActiveOfferContainer>

        <Label>Utløpte annonser</Label>
        <Hr />

        <ExpiredOfferContainer>
          {loadingExpiredAdvertisements ? (
            <p>{<Error>{error}</Error> || 'Loading...'}</p>
          ) : (
            <>
              {expiredAdvertisements.length === 0 ? (
                <p>Det finnes ingen utløpte annonser</p>
              ) : (
                expiredAdvertisements.map((expiredOffer) => (
                  <ExpiredOfferList key={expiredOffer.id}>
                    <Image />
                    <OfferText>
                      <HeaderH2 noMargin>{expiredOffer.subject}</HeaderH2>
                      <CategoryParagraph noMargin>
                        {expiredOffer.category
                          ? expiredOffer.category
                          : 'Kategori mangler'}
                      </CategoryParagraph>
                      <CustomParagraph noMargin>Utløpt</CustomParagraph>
                      <ButtonContainer>
                        <DeleteButton
                          style={{ paddingLeft: '0' }}
                          onClick={() => deleteOffer(expiredOffer.id)}
                        >
                          <Trash size={14} />
                          Slett
                        </DeleteButton>
                      </ButtonContainer>
                    </OfferText>
                  </ExpiredOfferList>
                ))
              )}
            </>
          )}
        </ExpiredOfferContainer>
      </OfferListStyles>
    </OfferListContainer>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  margin-top: auto;
`;

const DeleteButton = styled.button`
  border: none;
  background: none;
  color: var(--blue);
  font-size: 1.3rem;

  display: flex;
  min-width: 73px;
  align-items: center;

  svg {
    margin-right: 0.3rem;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: var(--blue);
  }
`;

const EditButton = styled(DeleteButton)`
  min-width: unset;
  margin: 0;
`;

const ActiveAdvertisementList = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 2px solid var(--lightGrey);
  &:first-child {
    padding-top: 0;
  }
`;

const Error = styled.span`
  color: var(--red);
`;

const Hr = styled.hr`
  color: var(--lightGrey);
  border-bottom: none;
`;

const ActiveOfferContainer = styled.div`
  margin-bottom: 5rem;
`;

const OfferListContainer = styled.div``;

const CustomHeaderH2 = styled(HeaderH2)`
  line-height: 1rem;
`;

const OfferListStyles = styled.div`
  padding: 1rem;
  max-width: var(--gridWidth);
  margin: 0 auto;
`;

const CustomParagraph = styled(Paragraph)`
  font-size: 1.2rem;
  line-height: 2rem;
`;

const CategoryParagraph = styled(Paragraph)`
  font-size: 1.2rem;
  margin-top: -1rem;
`;

const ExpiredOfferContainer = styled(ActiveOfferContainer)``;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  background-color: var(--lighestBlue);
  border: 1px solid transparent;
`;

const OfferText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  min-height: 80px;

  ${CustomParagraph} {
    max-width: 250px;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
`;

const ExpiredOfferList = styled(ActiveAdvertisementList)`
  &:last-of-type {
    margin-bottom: 5rem;
  }

  ${Image} {
    opacity: 0.5;
  }
  ${HeaderH2Styles} {
    opacity: 0.5;
  }
  ${CategoryParagraph} {
    opacity: 0.5;
  }
  ${CustomParagraph} {
    opacity: 0.5;
  }
`;
