import React, { useState, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import styled from 'styled-components';
import { Label } from './Label';
import { Base64Binary } from '../../services/base64-binary';
import Modal from '../Modal';
import useModal from '../../hooks/useModal';
import { Paragraph } from '../Paragraph';

export default function ImageResize({
  img,
  setImg,
  previewCroppedImage,
  setPreviewCroppedImage,
}) {
  const [srcImg, setSrcImg] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 16 / 7,
    unit: '%',
    width: 90,
    x: 5,
    y: 5,
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const { isShowing, toggle } = useModal();
  // const [field, meta] = useField(props);

  // called when the image is cropped
  useEffect(() => {
    if (result != null) {
      const uintArray = Base64Binary.decode(result);

      let binary = '';
      const len = uintArray.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uintArray[i]);
      }

      setImg({
        ...img,
        byteArray: window.btoa(binary),
      });
    }
  }, [result, setImg]);

  // initial upload of image
  const handleImage = async (event) => {
    setFile(event.target.files[0]);
    setResult(null);
    setPreviewCroppedImage('');
    setSrcImg(URL.createObjectURL(event.target.files[0]));

    const file = event.target.files[0];

    function createByteArray(file) {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = function (e) {
        const buffer = e.target.result;

        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }

        setImg({
          path: URL.createObjectURL(event.target.files[0]),
          byteArray: window.btoa(binary),
        });
      };
      reader.onerror = function (error) {
        setError(true);
      };
    }
    createByteArray(file);
  };

  // called when the image gets cropped
  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      // console.log('canvas', canvas);
      // console.log('image.naturalWidth', image.width);
      // console.log('naturalHeight', image.height);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      // converting canvas to base64 and lowering the quality
      // 1 = full quality 0.1 low quality
      let base64Image = canvas.toDataURL('image/png');

      setPreviewCroppedImage(base64Image);

      base64Image = base64Image.replace('data:image/png;base64', '');

      setResult(base64Image);

      setImg({
        path: canvas.toDataURL('image/png'),
        byteArray: base64Image,
      });
    } catch (e) {
      toggle();
      console.log(e);
    }
  };

  // getCroppedImg(crop, fileName) {
  //       let image = this.imageRef;
  //       const canvas = document.createElement('canvas');
  //       const scaleX = image.naturalWidth / image.width;
  //       const scaleY = image.naturalHeight / image.height;
  //       canvas.width = Math.ceil(crop.width*scaleX);
  //       canvas.height = Math.ceil(crop.height*scaleY);
  //       const ctx = canvas.getContext('2d');
  //       ctx.drawImage(
  //         image,
  //         crop.x * scaleX,
  //         crop.y * scaleY,
  //         crop.width * scaleX,
  //         crop.height * scaleY,
  //         0,
  //         0,
  //         crop.width*scaleX,
  //         crop.height*scaleY,
  //       );
  //       // As Base64 string
  //       // const base64Image = canvas.toDataURL('image/jpeg');
  //       // As a blob
  //       return new Promise((resolve, reject) => {
  //         canvas.toBlob(blob => {
  //           blob.name = fileName;
  //           resolve(blob);
  //         }, 'image/jpeg',1);
  //       });
  //     }

  // const getCroppedImg = () => {
  //   const canvas = document.createElement('canvas');

  //   const startX = image.naturalWidth * (image.x / 100);
  //   const startY = image.naturalHeight * (image.y / 100);
  //   const cropWidth = image.naturalWidth * (image.width / 100);
  //   const cropHeight = image.naturalHeight * (image.height / 100);

  //   canvas.width = cropWidth;
  //   canvas.height = cropHeight;

  //   const ctx = canvas.getContext('2d');
  //   ctx.imageSmoothingQuality = 'high';

  //   ctx.drawImage(
  //     image,
  //     startX,
  //     startY,
  //     cropWidth,
  //     cropHeight,
  //     0,
  //     0,
  //     cropWidth,
  //     cropHeight
  //   );

  //   let base64Image = canvas.toDataURL('image/png');

  //   setPreviewCroppedImage(base64Image);

  //   base64Image = base64Image.replace('data:image/png;base64', '');

  //   setResult(base64Image);

  //   setImg({
  //     path: canvas.toDataURL('image/png'),
  //     byteArray: base64Image,
  //   });
  // };

  return (
    <div>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title="Ops!"
        message="Noe gikk galt, prøv igjen."
      />
      <div className="mb-3">
        <Label style={{ marginBottom: '1rem' }}>Last opp bilde:</Label>
        <div>
          <input type="file" accept="image/*" onChange={handleImage} />
        </div>

        <CropContainer>
          {previewCroppedImage ? (
            <div style={{ marginTop: '2rem' }}>
              <img src={previewCroppedImage} alt="croppedimage" />
            </div>
          ) : (
            <>
              <ReactCrop
                src={img?.path}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
              />
              {srcImg && (
                <button
                  type="button"
                  className="cropButton"
                  onClick={() => getCroppedImg()}
                >
                  Beskjær
                </button>
              )}
              <Paragraph noMargin>
                Vi anbefaler å bruke et bilde i landskapsformat.
              </Paragraph>
              <Paragraph noMargin>
                Optimalt størrelseforhold er 550px bredt og 260px høyt.
              </Paragraph>
              <Paragraph noMargin>
                Du vil se en forhåndsvisning av annonsen på neste side.
              </Paragraph>
            </>
          )}
        </CropContainer>

        {error && <div>Noe gikk galt, prøv igjen</div>}
      </div>
    </div>
  );
}

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  > div {
    display: block;
    max-width: 300px;

    @media (min-width: 768px) {
      max-width: fit-content;
    }

    img {
      max-height: 400px;
    }
  }

  button {
    max-width: 130px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.6rem;
    color: white;
    transition: all 0.2s;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.2rem 3rem;
    background: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 5rem;
    min-width: 195px;
    width: fit-content;

    &:hover {
      cursor: pointer;
      filter: brightness(90%);
    }
  }
`;
