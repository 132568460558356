import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SanityBlockContent from '@sanity/block-content-to-react';

import { motion } from 'framer-motion';
import { serializers } from '../utils/serializer';

import { Header } from '../components/Header';
import { TextSection } from '../components/TextSection';
import { ImageMapUpsideDown } from '../components/BackgroundMapUpsideDown';
import { TextContainer } from '../components/TextContainer';
import { containerPage, itemPage } from '../utils/framer-motion-config';

export default function CoomingSoonPage({ data }) {
  const sanityContent = data.comingsoonpage.contentSections;
  const LastPartOfSanityContent = sanityContent.slice(1);

  return (
    <CoomingSoonContainer
      variants={containerPage}
      initial="hidden"
      animate="show"
    >
      <ImageMapUpsideDown />
      <CoomingSoonContent variants={itemPage}>
        <TextContainer>
          <TextSection>
            <Header big bolder>
              {sanityContent[0].title}
            </Header>
            <SanityBlockContent
              blocks={sanityContent[0]._rawText}
              serializers={serializers}
              projectId={process.env.SANITY_PROJECT_ID}
              dataset={process.env.SANITY_DATASET}
            />
          </TextSection>

          {LastPartOfSanityContent.map((content, index) => (
            <TextSection key={index}>
              <Header>{content.title}</Header>
              <SanityBlockContent
                blocks={content._rawText}
                serializers={serializers}
                projectId={process.env.SANITY_PROJECT_ID}
                dataset={process.env.SANITY_DATASET}
              />
            </TextSection>
          ))}
        </TextContainer>
      </CoomingSoonContent>
    </CoomingSoonContainer>
  );
}

export const query = graphql`
  query ComingSoonQuery {
    comingsoonpage: sanityPage(
      id: { eq: "-e500ff22-000e-5e6f-8ff2-d6ff102d192d" }
    ) {
      contentSections {
        ... on SanityTextSection {
          title
          subtitle
          _rawText
        }
      }
    }
  }
`;

const CoomingSoonContent = styled(motion.div)`
  max-width: var(--gridWidth);
  padding: 3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 60;
  position: relative;

  @media (min-width: 1024px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto 0 auto;
    padding: 8.6rem 3.4rem 0 3.4rem;
  }
`;

const CoomingSoonContainer = styled(motion.div)`
  background-color: var(--lightGrey);
  transition: 0.2s ease-in-out;
`;
