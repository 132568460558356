/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { ErrorMessage } from './ErrorMessage';

export const RadioButton = ({ text, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <function > and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  return (
    <Radio>
      <RadioInputContainer>
        <RadioInput {...field} {...props} />
        <RadioControl />
      </RadioInputContainer>
      <RadioLabel htmlFor={props.id || props.name}>{text}</RadioLabel>
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </Radio>
  );
};

const Radio = styled.label`
  font-size: 1.6rem;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;

  ${ErrorMessage} {
    grid-column: 1 / -1;
  }
`;

const RadioInputContainer = styled.span`
  display: flex;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
`;

const RadioControl = styled.span`
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  border: 0.1em solid currentColor;
  transform: translateY(-0.05em);
  display: grid;
  place-items: center;
`;

const RadioInput = styled.input`
  &:checked + ${RadioControl}:before {
    transform: scale(1);
  }

  &:checked + ${RadioControl} {
    color: var(--blue);
    border: 0.7rem solid currentColor;
  }
`;

const RadioLabel = styled.span`
  display: flex;
  align-items: center;
`;
