import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { format } from 'date-fns';
import { advertisementValidation } from '../../utils/validationSchema';

import { useUserContext } from '../../context/UserContext';
import { useCreateAdvertisementContext } from '../../context/CreateAdvertisementContext';

import { Label } from './Label';
import { TextArea } from './TextArea';
import { Button } from '../Buttons/Button';
import { FormSection } from './FormSection';
import { CompanyInfo } from '../CompanyInfo';
import { Input as CustomInput } from './Input';
import { updateAdvertisement } from '../../services/advertisements';

export default function EditAdvertisement() {
  const [error, setError] = useState(false);

  const { user, choosenCompany } = useUserContext();

  const {
    img,
    advertisementType,
    advertisements,
    setAdvertisements,
    currentAdvertisement,
    setCurrentAdvertisement,
  } = useCreateAdvertisementContext();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    function createByteArray(file) {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = function (e) {
        const buffer = e.target.result;

        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }

        setCurrentAdvertisement({
          ...currentAdvertisement,
          img: {
            path: URL.createObjectURL(event.target.files[0]),
            byteArray: window.btoa(binary),
          },
        });
      };
      reader.onerror = function (error) {
        setError(true);
      };
    }
    createByteArray(file);
  };

  const editAdvertisement = async (advertisement) => {
    const response = await updateAdvertisement(
      user.accessToken,
      currentAdvertisement.id,
      advertisement,
      currentAdvertisement.imageUrls,
      choosenCompany.id,
      choosenCompany.communityId
    );

    if (response.status > 300 || response === undefined) {
      setError('Ops! Noe gikk galt, prøv å laste siden på nytt.');
    } else {
      const newAdvertisements = [...advertisements];
      newAdvertisements.push(response.data);
      setAdvertisements(newAdvertisements);
      navigate(`/app/my-page/${choosenCompany.id}`);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          title: currentAdvertisement.subject,
          description: currentAdvertisement.content,
          startDate: format(new Date(currentAdvertisement.start), 'yyyy-MM-dd'),
          endDate: format(new Date(currentAdvertisement.end), 'yyyy-MM-dd'),
          discountCode: currentAdvertisement.discountCode,
          externalUrl:
            currentAdvertisement.externalUrl &&
            currentAdvertisement.externalUrl,
          img: currentAdvertisement.img,
          advertisementType: currentAdvertisement.advertisementType,
        }}
        enableReinitialize
        validationSchema={advertisementValidation}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          editAdvertisement(values);
          setSubmitting(false);
          resetForm();
        }}
      >
        {(props) => (
          <FormStyles>
            <OfferFor>Rediger tilbud for:</OfferFor>

            <CompanyInfo
              companyName={choosenCompany.name}
              category={choosenCompany.choosenCompanyCategory}
              companyImg={choosenCompany.thumbnailUrl}
            />

            {/** TITLE */}
            <FormSectionMBL>
              <CustomInput
                label="Tittel"
                name="title"
                type="text"
                boldPlaceholder
                placeholder="Tittel på tilbudet"
              />
            </FormSectionMBL>

            {/** DESCRIPTION */}
            <FormSection>
              <TextArea
                label="Beskrivelse"
                name="description"
                type="text"
                placeholder="Her skriver du litt om tilbudet"
              />
            </FormSection>

            {/** LINK */}
            {advertisementType === 1 && (
              <FormSection>
                <CustomInput
                  label="Link"
                  name="externalUrl"
                  type="text"
                  boldPlaceholder
                  placeholder="Link til bedriftens hjemmeside"
                />
              </FormSection>
            )}

            {/** IMAGE */}
            <FormSectionMBL>
              <Label>Last opp nytt bilde:</Label>
              <FileUploadInput
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  props.setFieldValue('file', event.currentTarget.files[0]);
                  handleFileChange(event);
                }}
              />
              <p>Nåværende bildet</p>
              <Image
                src={
                  currentAdvertisement.img?.path
                    ? currentAdvertisement.img?.path
                    : currentAdvertisement.imageUrls
                }
                id="preview-image"
                alt=""
              />
              {error && <ErrorMessage>Noe gikk galt, prøv igjen</ErrorMessage>}
            </FormSectionMBL>

            <HeaderH2>Spesifiser tilbudet ditt</HeaderH2>

            {currentAdvertisement.advertisementType === 0 && (
              <>
                {/** START DATE */}
                <FormSection>
                  <CustomInput label="Startdato" name="startDate" type="date" />
                </FormSection>

                {/** END DATE */}
                <FormSection>
                  <CustomInput label="Utløpsdato" name="endDate" type="date" />
                </FormSection>
              </>
            )}

            {/** DISCOUNT CODE */}
            {currentAdvertisement.advertisementType === 0 && (
              <FormSectionMBL>
                <CustomInput
                  label="Legg til rabattkode"
                  name="discountCode"
                  type="text"
                  placeholder="Rabattkode"
                />
              </FormSectionMBL>
            )}

            <FormSectionButtons>
              <CancelLink to={`/app/my-page/${choosenCompany.id}`}>
                Avbryt
              </CancelLink>
              <Button color="green" type="submit">
                Lagre
              </Button>
            </FormSectionButtons>
          </FormStyles>
        )}
      </Formik>
    </div>
  );
}

const FormSectionMBL = styled(FormSection)`
  margin-bottom: 7rem;
`;

const FormSectionButtons = styled(FormSection)`
  margin-bottom: 7rem;
  flex-direction: column;

  button:first-child {
    margin-right: 2rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const FileUploadInput = styled.input`
  margin-bottom: 2rem;
  max-width: 300px;
`;

const FormStyles = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: var(--gridWidth);
  margin: 0 auto;
`;

const Image = styled.img`
  max-height: 280px;
  max-width: 320px;
  object-fit: cover;
  aspect-ratio: 16 / 7;

  @media (min-width: 768px) {
    max-width: 360px;
  }
`;

const HeaderH2 = styled.h2`
  font-size: clamp(1.8rem, 3vw, 2.4rem);
  margin-bottom: 2rem;
  font-weight: 700;
`;

const OfferFor = styled.p`
  font-size: clamp(1.2rem, 3vw, 1.8rem);
`;

const CancelLink = styled(Link)`
  text-decoration: none;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
  transition: all 0.2s;
  margin-bottom: 2rem;
  padding: 1.2rem 3rem;
  background: var(--red);
  border: 1px solid var(--red);
  border-radius: 5rem;
  margin-right: 2rem;
  min-width: 195px;
  width: fit-content;
  text-align: center;
`;
