import React from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import { Paragraph, ParagraphStyles } from './Paragraph';
import { LinkButton } from './Buttons/LinkButton';

export const CTACard = ({
  title,
  text,
  linktext,
  invertedButton,
  linkTo,
  big,
  noMargin,
  children,
  className,
}) => (
  <CTACardStyles className={className}>
    {title && <CustomHeader>{title}</CustomHeader>}
    {text && (
      <Paragraph big={big} noMargin={noMargin}>
        {text}
      </Paragraph>
    )}
    {linktext && (
      <LinkButton invertedButton={invertedButton} link={linkTo}>
        {linktext}
      </LinkButton>
    )}
    {children}
  </CTACardStyles>
);

const CustomHeader = styled(Header)`
  line-height: 3.1rem; ;
`;

export const CTACardStyles = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--lightGrey);
  border-radius: 1.2rem;
  box-shadow: 0px 4px 24px hsl(0, 0%, 0%, 25%);
  padding: 4rem;
  max-width: 326px;
  text-align: center;
  z-index: 50;

  ${ParagraphStyles} {
    flex: 1;
    font-size: 2rem;
  }

  a {
    min-width: 200px;
    max-width: 220px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    max-width: 375px;
  }
`;
