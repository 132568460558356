import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { isBrowser } from '../../services/auth';
import { useUserContext } from '../../context/UserContext';
import { getCompanies } from '../../services/companies';

import { TextBlock } from '../TextBlock';
import { IconGrid } from '../IconGrid';
import { CompanyButton } from '../Buttons/CompanyButton';

import { House } from '../../assets/icons/House';
import { Heart } from '../../assets/icons/Heart';
import { Music } from '../../assets/icons/Music';
import { Loader } from '../../assets/icons/Loader';
import { Calendar } from '../../assets/icons/Calendar';
import { RedDotWhiteCirle } from '../../assets/icons/RedDotWhiteCirle';
import { GreenDotWhiteCirle } from '../../assets/icons/GreenDotWhiteCircle';

export default function MyCompanies() {
  const { user, companies, choosenCompany, setChoosenCompany, setCompanies } =
    useUserContext();
  const [loading, setLoading] = useState(false);

  const handleClick = (id, name, category, communityId, thumbnailUrl) =>
    setChoosenCompany({ id, name, category, communityId, thumbnailUrl });

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getCompanies(user.id, user.accessToken);
      setCompanies(response);
      setLoading(false);
    }
    fetchData();
  }, [user.id, user.accessToken, setCompanies]);

  useEffect(() => {
    if (companies?.length === 1) {
      setChoosenCompany({
        id: companies[0].id,
        name: companies[0].name,
        category:
          companies[0].companyCategory && companies[0].companyCategory.name,
        communityId: companies[0].communityId || '',
        thumbnailUrl: companies[0].thumbnailUrl || '',
      });
      navigate(`/app/my-page/${companies[0].id}`);
    }
    localStorage.setItem('companies', JSON.stringify(companies));
  }, [companies, setChoosenCompany]);

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem(
        'choosenCompanyId',
        choosenCompany.id ? choosenCompany.id : undefined
      );
      localStorage.setItem(
        'choosenCompanyName',
        choosenCompany.name ? choosenCompany.name : undefined
      );
      localStorage.setItem(
        'choosenCompanyCategory',
        choosenCompany.category ? choosenCompany.category.name : undefined
      );
      localStorage.setItem(
        'choosenCompanyCommunityId',
        choosenCompany.communityId ? choosenCompany.communityId : undefined
      );
      localStorage.setItem(
        'choosenCompanyThumbnailUrl',
        choosenCompany.thumbnailUrl ? choosenCompany.thumbnailUrl : undefined
      );
    }
  }, [choosenCompany]);

  return (
    <MyCompaniesContainer>
      <MyCompaniesContent>
        {companies?.length < 1 ? (
          <TextBlock
            noMargin
            title="Ops!"
            text="For å bruke denne funksjonaliteten må du ha registrert en bedrift i appen som du administrerer."
          />
        ) : (
          <>
            <TextBlock
              noMargin
              title="Velg ditt selskap"
              text="Her ser du en oversikt alle selskapene du administerer."
            />
            {loading ? (
              <LoaderContainer>
                <Loader size={75} />
              </LoaderContainer>
            ) : (
              <CompanyListContainer>
                {companies?.length > 1 ? (
                  companies?.map((company) => (
                    <CustomLink
                      key={company.id}
                      to={`/app/my-page/${company.id}`}
                    >
                      <CompanyButton
                        companyImg={company.thumbnailUrl}
                        companyName={company.name}
                        handleClick={() =>
                          handleClick(
                            company.id,
                            company.name,
                            company.companyCategory || '',
                            company.communityId || '',
                            company.thumbnailUrl || ''
                          )
                        }
                      />
                    </CustomLink>
                  ))
                ) : (
                  <LoaderContainer>
                    <Loader size={75} />
                  </LoaderContainer>
                )}
              </CompanyListContainer>
            )}
          </>
        )}
      </MyCompaniesContent>

      <IconContainer>
        <IconGrid>
          <Music size={46} />
          <House size={90} />
          <Calendar size={90} />
          <GreenDotWhiteCirle size={30} />
          <RedDotWhiteCirle size={20} />
          <Heart size={90} />
        </IconGrid>
      </IconContainer>
    </MyCompaniesContainer>
  );
}

const IconContainer = styled.div`
  position: relative;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 200px;
  justify-content: center;
`;

const CustomLink = styled(Link)`
  display: flex;
  text-decoration: none;
  padding: 0 3rem;

  @media (min-width: 1080px) {
    padding: 0;
  }
`;

const MyCompaniesContent = styled.div`
  max-width: var(--gridWidth);
  margin: 0 auto;
`;

const MyCompaniesContainer = styled.div`
  background-color: var(--seaGreen);
  flex: 1;
  transition: transform 0.9s ease-in-out;
`;

const CompanyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
