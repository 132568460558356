import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import BackgroundMap from '../components/BackgroundMap';
import { CTACard, CTACardStyles } from '../components/CTACard';

import Roads from '../assets/images/Roads_2.png';
import { House, HouseSVG } from '../assets/icons/House';
import { ActivityCard } from '../components/ActivityCard';
import TableImage from '../assets/images/ActivityCardTable.png';
import BarberImage from '../assets/images/ActivityCardBarber.png';
import Workplace from '../assets/images/ActivityCardWorkplace.png';
import { CalendarPin, CalendarPinSVG } from '../assets/icons/CalendarPin';
import { WorkPin, WorkPinSVG } from '../assets/icons/WorkPin';
import {
  AdvertisementPin,
  AdvertisementPinSVG,
} from '../assets/icons/AdvertisementPin';
import { BlueDot, BlueDotSVG } from '../assets/icons/BlueDot';
import { RedDotBigRedCirleSVG } from '../assets/icons/RedDotBigRedCirle';
import { container, item } from '../utils/framer-motion-config';

export default function HomePage({ data }) {
  const sanityContent = data.homepage.contentSections;
  const heroImages = data.heroimages.contentSections;

  const Images = heroImages[Math.floor(Math.random() * heroImages.length)];

  return (
    <HomePageContainer
      variants={container}
      initial="hidden"
      animate="show"
      layout
    >
      <HomePageContent>
        <Overlay />

        <ImageHero
          variants={item}
          image={getImage(Images.asset)}
          alt={Images.alt}
        />

        <Image src={Roads} alt="Map in the background" />

        <CTAContainer>
          <CTACard
            center
            bigPaddingBottom
            title={sanityContent[0].title}
            text={sanityContent[0].text}
            linktext={sanityContent[0].link}
            linkTo="http://onelink.to/ey33g6"
          />
          <CTACard
            center
            bigPaddingBottom
            title={sanityContent[1].title}
            text={sanityContent[1].text}
            linktext={sanityContent[1].link}
            linkTo="/app/my-companies"
            invertedButton
          />
        </CTAContainer>

        <MapContainer
          color="var(--blue)"
          variants={container}
          imageHeight="1200"
        >
          <House size={93} variants={item} />
          <WorkPin variants={item} />
          <AdvertisementPin variants={item} />
          <ActivityCardWorkplace src={Workplace} alt="" variants={item} />
          <ActivityCardTable src={TableImage} alt="" variants={item} />
          <ActivityCardBarber src={BarberImage} alt="" variants={item} />
          <CalendarPin variants={item} />
          <BlueDot size={58} variants={item} />
        </MapContainer>
      </HomePageContent>
    </HomePageContainer>
  );
}

export const query = graphql`
  query HomeQuery {
    homepage: sanityPage(id: { eq: "-65fb22cc-42cd-5ac6-8c1e-1563a6e1e1ff" }) {
      title
      contentSections {
        ... on SanityCardSection {
          link
          text
          title
        }
      }
    }
    heroimages: sanityHeroimages {
      title
      contentSections {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
    }
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: var(--gridWidth);
  margin: 0 auto;
  margin-top: 20rem;
  z-index: 60;
  position: relative;
  padding: 0 2rem;

  ${CTACardStyles} {
    &:first-child {
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 1080px) {
    flex-direction: row;
    align-items: initial;
    margin-top: 24rem;

    ${CTACardStyles} {
      &:first-child {
        margin-right: 0.8rem;
        margin-bottom: 0;
      }

      &:last-child {
        margin-left: 0.8rem;
      }
    }
  }

  @media (min-width: 1600px) {
    margin-top: 33rem;
  }
`;

const ActivityCardBarber = styled(ActivityCard)``;

const ActivityCardWorkplace = styled(ActivityCard)``;

const ActivityCardTable = styled(ActivityCard)``;

const HomePageContainer = styled(motion.div)`
  min-height: 100vh;
`;

const HomePageContent = styled(motion.div)`
  background-color: var(--lightGrey);
  position: relative;
  overflow: hidden;
`;

const ImageHero = styled(GatsbyImage)`
  position: absolute;
  min-height: 445px;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  z-index: 5;

  @media (min-width: 1024px) {
    max-width: var(--maxGridWidth);
    margin: 0 auto;
    min-height: 545px;
  }
`;

const Image = styled.img`
  position: absolute;
  min-height: 1232px;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  max-width: 100vw;
  margin: 0 auto;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 5;
  background: linear-gradient(
    0deg,
    rgba(239, 242, 249, 0) 10%,
    var(--blue) 72%
  );

  @media (min-width: 1080px) {
    background: linear-gradient(
      0deg,
      rgba(239, 242, 249, 0) 10%,
      var(--blue) 72%
    );
  }
`;

const MapContainer = styled(BackgroundMap)`
  flex: 1;
  max-width: 100vw;
  margin: -26rem auto 0 auto;
  z-index: 5;

  ${AdvertisementPinSVG} {
    height: auto;
    width: 97px;
    z-index: 10;
    grid-column: 2;
    grid-row: 11;
    margin-top: -4rem;
  }

  ${WorkPinSVG} {
    z-index: 10;
    height: auto;
    width: 98px;
    grid-column: 3;
    grid-row: 5;
  }

  ${CalendarPinSVG} {
    z-index: 10;
    height: auto;
    width: 92px;
    grid-column: 1;
    margin-left: -3rem;
    grid-row: 6;
  }

  ${RedDotBigRedCirleSVG} {
    z-index: 10;
    grid-column: 2;
    grid-row: 6;
  }

  ${BlueDotSVG} {
    z-index: 10;
    grid-column: 2;
    grid-row: 5;
  }

  ${ActivityCardBarber} {
    z-index: 11;
    grid-column: 3 / 8;
    grid-row: 9 / 12;
    margin-left: 2rem;
  }

  ${ActivityCardWorkplace} {
    z-index: 9;
    grid-column: 6 / 13;
    grid-row: 6 / 10;
    margin-left: -6rem;
    margin-top: -1rem;
  }

  ${ActivityCardTable} {
    z-index: 10;
    grid-column: 1 / 3;
    grid-row: 8 / 11;
    margin-left: -2rem;
  }

  ${HouseSVG} {
    z-index: 10;
    grid-column: 8;
    grid-row: 4;
    margin-top: -4rem;
    margin-right: -2rem;
  }

  @media (min-width: 1080px) {
    max-width: var(--maxGridWidth);
    margin: -26rem auto 0 auto;

    ${ActivityCardWorkplace} {
      z-index: 10;
      grid-column: 6 / 9;
      grid-row: 7 / 10;
      margin-left: 0;
      margin-top: -2rem;
    }

    ${ActivityCardTable} {
      grid-column: 3 / 7;
      grid-row: 10 / 13;
      margin-left: 0;
    }

    ${ActivityCardBarber} {
      z-index: 11;
      grid-column: 8 / 12;
      grid-row: 9 / 12;
      margin-left: 2rem;
    }

    ${AdvertisementPinSVG} {
      height: auto;
      grid-column: 10;
      grid-row: 6;
      margin-top: 0;
    }

    ${CalendarPinSVG} {
      height: auto;
      width: 98px;
      grid-column: 13;
      grid-row: 8;
    }

    ${WorkPinSVG} {
      height: auto;
      width: 98px;
      grid-column: 3;
      grid-row: 5;
    }

    ${RedDotBigRedCirleSVG} {
      grid-column: 4;
      grid-row: 8;
    }

    ${BlueDotSVG} {
      grid-column: 3;
      grid-row: 8;
    }

    ${HouseSVG} {
      grid-column: 11;
      grid-row: 5;
      margin-top: 0;
      margin-right: 0;
    }
  }
`;
