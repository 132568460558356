// READ
export const getAdvertisements = async (companyId, accessToken) => {
  try {
    const url = `https://communityas-api.azurewebsites.net/api/Advertisement/all/${companyId}`;
    const body = JSON.stringify({
      page: 1,
      pageSize: 50,
      isFullSize: true,
    });
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
};

// DELETE
export const deleteAdvertisement = async (advertisementId, accessToken) => {
  try {
    const url = `https://communityas-api.azurewebsites.net/api/Advertisement/${advertisementId}`;
    const res = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await res;
    return data;
  } catch (err) {
    console.error(err);
  }
};

// CREATE TILBUDSANNONSE
export const createAdvertisement = async (
  accessToken,
  title,
  description,
  img,
  startDate,
  endDate,
  discountCode,
  companyId,
  communityId,
  advertisementType
) => {
  try {
    const url = `https://communityas-api.azurewebsites.net/api/Advertisement/base64`;

    const body = JSON.stringify({
      subject: title,
      active: true,
      content: description,
      images: [
        {
          extention: 'png',
          image: img.byteArray,
        },
      ],
      companyId,
      communityIds: [parseInt(communityId)],
      discountCode,
      start: startDate,
      end: endDate,
      showInNearbyCommunities: true,
      advertisementType,
    });

    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    });
    const data = await res.json();
    // console.log('createAdvertisement', data);
    return data;
  } catch (err) {
    console.error(err);
  }
};

// CREATE PROFIL ADVERTISEMENT
export const createProfileAdvertisement = async (
  accessToken,
  title,
  description,
  img,
  startDate,
  endDate,
  companyId,
  communityId,
  externalUrl,
  advertisementType
) => {
  try {
    const url = `https://communityas-api.azurewebsites.net/api/Advertisement/base64`;

    const body = JSON.stringify({
      subject: title,
      active: true,
      content: description,
      images: [
        {
          extention: 'png',
          image: img.byteArray,
        },
      ],
      companyId,
      communityIds: [parseInt(communityId)],
      start: startDate,
      end: endDate,
      showInNearbyCommunities: true,
      externalUrl: externalUrl && externalUrl,
      advertisementType,
    });

    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
};

// Update
export const updateAdvertisement = async (
  accessToken,
  advertisementId,
  advertisement,
  imageUrls,
  companyId,
  communityId
) => {
  try {
    const url = `https://communityas-api.azurewebsites.net/api/Advertisement/base64`;

    const body = JSON.stringify({
      id: advertisementId,
      subject: advertisement.title,
      active: true,
      content: advertisement.description,
      imageUrls: advertisement.img ? [] : imageUrls,
      images: [
        {
          extention: 'png',
          image: advertisement.img && advertisement.img.byteArray,
        },
      ],
      companyId,
      communityIds: [parseInt(communityId)],
      discountCode: advertisement.discountCode,
      start: advertisement.startDate,
      end: advertisement.endDate,
      showInNearbyCommunities: true,
      externalUrl: advertisement.externalUrl && advertisement.externalUrl,
    });

    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
};
