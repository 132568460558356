import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const GreenDotWhiteCirle = ({ size, className, variants }) => (
  <GreenDotWhiteCirleSVG
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/GreenDotWhiteCirleSVG"
    className={className}
    variants={variants}
  >
    <g opacity="0.3">
      <circle cx="14.5" cy="14.5" r="14.5" fill="white" />
      <circle cx="14.5" cy="14.5" r="14.5" fill="white" />
      <circle cx="14.5" cy="14.5" r="14.5" fill="white" />
    </g>
    <ellipse cx="14.5" cy="14" rx="5.5" ry="6" fill="#74E2BE" />
  </GreenDotWhiteCirleSVG>
);

export const GreenDotWhiteCirleSVG = styled(motion.svg)`
  z-index: 120;
`;
