import styled from 'styled-components';

export const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 2rem;
  font-size: 1.6rem;
  color: var(--black);
  display: block;
`;

export const BoldLabel = styled.label`
  margin-bottom: 0.5rem;
  font-family: 'Poppins';
  font-weight: bold;
  line-height: 2.4rem;
  font-size: 1.6rem;
  display: block;
`;
